import React from 'react';
import styled from '@emotion/styled';

type Props = {
  openMobileMenu: boolean;
  setOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

export const MenuToggleButton: React.FC<Props> = ({
  openMobileMenu,
  setOpenMobileMenu,
}: Props) => {
  const handleClick = () => {
    setOpenMobileMenu(!openMobileMenu);
  };
  return (
    <StyleMenuToggleButton className={`header-menu-toggler`}>
      <StyleButton
        className={`${openMobileMenu ? 'mobile-menu-open' : ''}`}
        onClick={handleClick}
      >
        <i />
        <i />
        <i />
      </StyleButton>
    </StyleMenuToggleButton>
  );
};

const StyleMenuToggleButton = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 101;
  width: 30px;
  height: 30px;
`;

const StyleButton = styled.button`
  --width: 30px;
  --height: 22px;
  --size-border: 2px;
  position: relative;
  cursor: pointer;
  border: none;
  border-radius: 0;
  background: none;
  padding: 0;
  width: var(--width);
  height: var(--height);
  display: flex;
  align-items: center;
  display: inline-block;
  box-sizing: border-box;

  i {
    display: inline-block;
    box-sizing: border-box;
    transition: 0.05s ease-in-out;
    position: absolute;
    left: 0;
    width: 100%;
    height: var(--size-border);
    background-color: black;

    &:nth-of-type(1) {
      top: 0;
    }

    &:nth-of-type(2) {
      top: calc(var(--height) * 0.5 - var(--size-border) * 0.5);
    }

    &:nth-of-type(3) {
      bottom: 0;
    }
  }

  &:focus {
    outline: none;
  }

  &.mobile-menu-open {
    i {
      &:nth-of-type(1) {
        transform: translateY(
            calc(var(--height) * 0.5 - var(--size-border) * 0.5)
          )
          rotate(-45deg);
      }

      &:nth-of-type(2) {
        opacity: 0;
      }

      &:nth-of-type(3) {
        transform: translateY(
            calc(var(--height) * -0.5 + var(--size-border) * 0.5)
          )
          rotate(45deg);
      }
    }
  }
`;

export default MenuToggleButton;
