import React, { useState, useEffect } from 'react';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/react';
import loaderSVG from 'src/images/loader.svg';

export const Loader: React.FC = () => {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const id = setTimeout(() => {
      setShow(true);
    }, 10);
    return () => {
      clearTimeout(id);
    };
  }, []);
  return <StyleLoaderWave className={`${show ? 'show' : ''}`} />;
};

const loading = keyframes`
  0% {
    background-position: 0px center;
  }
  100% {
    background-position: -160px center;
  }
`;

const StyleLoaderWave = styled.div`
  position: fixed;
  z-index: 1000;
  top: 55%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 160px;
  height: 8px;
  background-size: contain;
  background-repeat: repeat-x;
  background-image: url(${loaderSVG});
  animation: ${loading} 1.4s ease-in-out infinite;
  opacity: 0;
  transition: opacity 0.2s linear;
  &.show {
    opacity: 1;
  }
`;

export default Loader;
