import * as React from 'react';
import styled from '@emotion/styled';
import { Global, css, ThemeProvider } from '@emotion/react';
import {
  PathnameContext,
  MobileWidthContext,
  QueryStringContext,
  LoadedPostsCacheContext,
} from 'src/contexts';
import '@reach/skip-nav/styles.css';

import {
  Header,
  Footer,
  SkipNavLink,
  TitleLarge,
  Transition,
} from 'src/components/common';
import { theme, reset } from 'src/styles';
import ResizeObserver from 'resize-observer-polyfill';

export type Props = {
  children: React.ReactNode;
  location: {
    pathname: string;
    search: string;
  };
};

const globalStyle = css`
  ${reset}
  h1, h2, h3, h4, h5, h6 {
    line-height: 1.4;
    margin-bottom: 0;
  }
  p {
    margin-bottom: 0;
  }
  html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  body {
    color: ${theme.colors.black};
    background-color: ${theme.colors.bg};
  }
  ::selection {
    color: ${theme.colors.bg};
    background-color: ${theme.colors.black};
  }
  a {
    color: inherit;
    text-decoration: none;
    &:hover,
    &:focus {
      text-decoration: underline;
    }
    &:visited {
      color: inherit;
    }
  }
  button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    outline: none;
    padding: 0;
    appearance: none;
    text-decoration: underline;
    &:hover {
      text-decoration: none;
    }
    &:focus {
      outline: none;
    }
  }
  ul,
  ol {
    margin-left: 0;
    list-style-position: inside;
  }
  img,
  svg {
    vertical-align: top;
    width: 100%;
    height: auto;
  }
  br {
    &.pc {
      @media (max-width: ${theme.breakpoints.ipadVertical}px) {
        display: none;
      }
    }
    &.sp {
      @media (min-width: ${theme.breakpoints.ipadVertical + 1}px) {
        display: none;
      }
    }
  }
`;

const useIsMobile: (target: React.RefObject<HTMLDivElement>) => boolean = (
  target
) => {
  const [isMobile, setIsMobile] = React.useState<boolean>(false);
  React.useLayoutEffect(() => {
    if (target.current) {
      const resizeObserver = new ResizeObserver((entries) => {
        entries.forEach((entry) => {
          if (entry.contentRect.width <= theme.breakpoints.ipadVertical) {
            setIsMobile(true);
          } else {
            setIsMobile(false);
          }
        });
      });

      resizeObserver.observe(target.current);

      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [target]);
  return isMobile;
};

const useIsMounted = () => {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useLayoutEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
};

const Layout: React.FC<Props> = ({
  children,
  location: { pathname, search },
}: Props) => {
  const site = React.useRef<HTMLDivElement>(null);
  const isMobile = useIsMobile(site);
  const [loadedPostsCache, setLoadedPostsCache] = React.useState<
    Record<string, unknown>
  >();
  const isMounted = useIsMounted();

  return (
    <div ref={site}>
      <Global styles={globalStyle} />
      <SkipNavLink />
      <PathnameContext.Provider value={{ pathname }}>
        <QueryStringContext.Provider value={{ search }}>
          <MobileWidthContext.Provider value={{ isMobile }}>
            <LoadedPostsCacheContext.Provider
              value={{ loadedPostsCache, setLoadedPostsCache }}
            >
              <ThemeProvider theme={theme}>
                <StylePage>
                  <Header />
                  {pathname === '/' && isMounted && <TitleLarge />}
                  <StyleMain
                    className={
                      pathname !== '/' && isMounted ? 'padding-top' : ''
                    }
                    id={'#reach-skip-nav'}
                  >
                    <Transition>{children}</Transition>
                  </StyleMain>
                  <Footer />
                </StylePage>
              </ThemeProvider>
            </LoadedPostsCacheContext.Provider>
          </MobileWidthContext.Provider>
        </QueryStringContext.Provider>
      </PathnameContext.Provider>
    </div>
  );
};

const StylePage = styled.div`
  --padding: ${theme.margin.site.sp};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  padding-left: var(--padding);
  padding-right: var(--padding);
  background-color: white;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    --padding: ${({ theme }) => theme.margin.site.pc};
  }

  main {
    flex-grow: 1;
  }
`;

const StyleMain = styled.main`
  padding-bottom: 200px;
  @media (min-width: ${theme.breakpoints.ipadVertical + 1}px) {
    padding-left: ${({ theme }) => theme.margin.main.pc};
    padding-right: ${({ theme }) => theme.margin.main.pc};
  }

  &.padding-top {
    padding-top: 30px;
    @media (min-width: ${theme.breakpoints.ipadVertical + 1}px) {
      padding-top: 50px;
    }
  }
`;

export default Layout;
