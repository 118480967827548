import * as React from 'react';
import styled from '@emotion/styled';
import dayjs from 'dayjs';
import { ScreenReaderText } from 'src/components/common';
import Bunkacho from 'src/images/bunkacho.png';
import Bunkaryoku from 'src/images/bunkaryoku.jpg';
import Opuoa from 'src/images/okinawa-prefectural-university-of-arts.png';

export const Footer: React.FC = () => {
  const currentYear = dayjs().format('YYYY');
  return (
    <StyleFooter className="footer">
      <StyleOuterlinks className="footer-outerlinks">
        <StyleMenuList>
          <StyleMenuListItem className="bunkacho">
            <a
              href="http://www.bunka.go.jp/index.html"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Bunkacho} alt="文化庁" />
              <ScreenReaderText>文化庁</ScreenReaderText>
            </a>
          </StyleMenuListItem>
          <StyleMenuListItem className="bunkaryoku">
            <a
              href="http://www.bunka.go.jp/bunkaryoku_project/daigaku/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Bunkaryoku} alt="大学から文化力" />
              <ScreenReaderText>大学から文化力</ScreenReaderText>
            </a>
          </StyleMenuListItem>
          <StyleMenuListItem className="opuoa">
            <a
              href="http://www.okigei.ac.jp/"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={Opuoa} alt="" />
              <ScreenReaderText>沖縄県立芸術大学</ScreenReaderText>
            </a>
          </StyleMenuListItem>
        </StyleMenuList>
      </StyleOuterlinks>
      <StyleText className="footer-text">
        <p>
          <a
            href="http://www.okigei.ac.jp/site/privacy.html"
            target="_blank"
            rel="noopener noreferrer"
          >
            プライバシーポリシー
          </a>
        </p>
        <p>
          Copyright ©2019-{currentYear} Okinawa Prefectural University of Arts
        </p>
      </StyleText>
    </StyleFooter>
  );
};

const StyleFooter = styled.footer`
  padding-bottom: 30px;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    display: flex;
    padding-bottom: 40px;
  }
  .footer-text {
    margin-left: auto;
  }
`;

const StyleOuterlinks = styled.div``;

const StyleMenuList = styled.ul`
  list-style: none;
  margin: 0;
  display: flex;
  align-items: center;

  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    justify-content: space-around;
  }
`;

const StyleMenuListItem = styled.li`
  &.bunkacho {
    width: 50px;
  }
  &.bunkaryoku {
    width: 30px;
  }
  &.opuoa {
    width: 150px;
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-bottom: 0;
    margin-right: 40px;
    &:last-of-type {
      margin-right: 0;
    }
    &.opuoa {
      width: 180px;
    }
  }
`;

const StyleText = styled.div`
  font-size: 10px;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    display: flex;
    align-items: flex-end;
    font-size: 11px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    margin-top: 20px;
    text-align: center;
  }

  p {
    margin: 0;
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      /* margin-right: 1em; */
      &::after {
        content: '｜';
        margin-left: 0.5em;
        margin-right: 0.5em;
      }
      &:last-of-type {
        &::after {
          content: none;
        }
      }
    }

    @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
      & + * {
        margin-top: 1.5em;
      }
    }
  }
`;

export default Footer;
