import * as React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { Helmet, HelmetProps } from 'react-helmet';
import type { GetSeoQuery } from 'graphql-type';

type MetaProps = NonNullable<HelmetProps['meta']>;
type Props = {
  pathname?: string;
  title?: string | null;
  description?: string | null;
  meta?: MetaProps;
  image?: string | null;
  imageWidth?: number | null;
  imageHeight?: number | null;
  isSingle?: boolean;
  firstPublicationDate?: string;
  lastPublicationDate?: string;
};

const query = graphql`
  query GetSeo {
    site {
      siteMetadata {
        title
        titleAlt
        description
        banner
        siteLanguage
        author
        siteUrl
        twitter
        facebookAppId
      }
    }
  }
`;

function useAddViewportFitCover() {
  React.useEffect(() => {
    // NOTE:
    // viewport-fit=coverを追加する。
    // viewportのmetaは.caches/default-html.jsに直接書かれているので、
    // 上書きできないため、ここで無理やり上書きする。
    const viewportMeta = document.head.querySelector<HTMLMetaElement>(
      'meta[name="viewport"]'
    );
    if (
      viewportMeta &&
      !viewportMeta.classList.contains('added-viewport-fit')
    ) {
      const defaultViewportContent = viewportMeta.getAttribute('content');
      if (defaultViewportContent) {
        viewportMeta.setAttribute(
          'content',
          `${defaultViewportContent}, viewport-fit=cover`
        );
        viewportMeta.classList.add('added-viewport-fit');
      }
    }
  }, []);
}

export const SEO: React.FC<Props> = ({
  pathname = '',
  title,
  description,
  meta,
  image,
  imageWidth = 1200,
  imageHeight = 630,
  isSingle = false,
  firstPublicationDate,
  lastPublicationDate,
}) => {
  useAddViewportFitCover();
  const { site } = useStaticQuery<GetSeoQuery>(query);
  const defaultTitle = site?.siteMetadata?.title ?? '';
  const pageTitle = title ?? defaultTitle ?? '';
  const defaultDescription = site?.siteMetadata?.description ?? '';
  const titleTemplate = title ? `%s｜${defaultTitle}` : `%s`;
  const pageDescription =
    description?.replace(/\r?\n/g, '')?.substr(0, 450) ??
    defaultDescription ??
    '';
  const siteUrl = site?.siteMetadata?.siteUrl || '';
  const url = `${siteUrl}${pathname}`;
  const defaultBanner = site?.siteMetadata?.banner ?? '';
  const seoImage = image ? `${siteUrl}${image}` : `${siteUrl}/${defaultBanner}`;
  const lang = site?.siteMetadata?.siteLanguage ?? 'ja';
  const defaultMeta = React.useMemo(
    () =>
      [
        {
          name: `description`,
          content: pageDescription,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:title`,
          content: pageTitle,
        },
        {
          property: `og:description`,
          content: pageDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:image`,
          content: seoImage,
        },
        {
          property: `og:image:width`,
          content: imageWidth,
        },
        {
          property: `og:image:height`,
          content: imageHeight,
        },
        {
          property: `fb:app_id`,
          content: site?.siteMetadata?.facebookAppId,
        },
        {
          name: `twitter:card`,
          content: `summary_large_image`,
        },
        {
          name: `twitter:creator`,
          content: site?.siteMetadata?.author,
        },
        {
          name: `twitter:title`,
          content: pageTitle,
        },
        {
          name: `twitter:description`,
          content: pageDescription,
        },
        {
          name: `twitter:image`,
          content: seoImage,
        },
      ] as MetaProps,
    [imageHeight, imageWidth, pageDescription, pageTitle, seoImage, site, url]
  );

  const schemaOrgJSONLD = [
    {
      '@context': 'http://schema.org',
      '@type': 'WebPage',
      '@id': siteUrl,
      url: siteUrl,
      name: defaultTitle,
      description: pageDescription,
      inLanguage: lang,
      mainEntityOfPage: siteUrl,
      author: {
        '@type': 'Person',
        name: site?.siteMetadata?.author,
      },
      image: {
        '@type': 'ImageObject',
        url: `${siteUrl}/${defaultBanner}`,
      },
    },
  ];

  // if (isSingle) {
  //   const author = site?.siteMetadata?.author || '@';
  //   schemaOrgJSONLD = [
  //     {
  //       '@context': 'http://schema.org',
  //       '@type': 'BlogPosting',
  //       '@id': url,
  //       url: url,
  //       name: title || '',
  //       alternateName: site?.siteMetadata?.titleAlt || '',
  //       headline: title || '',
  //       image: {
  //         '@type': 'ImageObject',
  //         url: seoImage,
  //       },
  //       description: description,
  //       datePublished: firstPublicationDate || undefined,
  //       dateModified: lastPublicationDate || undefined,
  //       author: {
  //         '@type': 'Person',
  //         name: author,
  //       },
  //       publisher: {
  //         '@type': 'Organization',
  //         name: author,
  //         logo: {
  //           '@type': 'ImageObject',
  //           url: `${siteUrl}/icons/icon-512x512.png`,
  //         },
  //       },
  //       isPartOf: siteUrl,
  //       mainEntityOfPage: {
  //         '@type': 'LocalBusiness',
  //         '@id': siteUrl,
  //       },
  //     },
  //   ];
  // }

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={titleTemplate}
      meta={meta ? [...defaultMeta, ...meta] : defaultMeta}
    >
      <script type="application/ld+json">
        {JSON.stringify(schemaOrgJSONLD)}
      </script>
    </Helmet>
  );
};

export default SEO;
