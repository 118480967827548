import * as React from 'react';
import styled from '@emotion/styled';

type Props = {
  className?: string;
  html: string;
};

export const Content: React.VFC<Props> = ({ className, html }) => (
  <Wrapper
    className={className ?? ''}
    dangerouslySetInnerHTML={{ __html: html }}
  />
);

const Wrapper = styled.div`
  h3 {
    text-align: center;
    font-size: ${({ theme }) => theme.fontSize.large.sp};
  }

  > * + * {
    margin-top: 2em;
  }

  > * + h3 {
    margin-top: ${({ theme }) => theme.margin.content.sp};
  }

  h3 + * {
    margin-top: 3em;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    h3 {
      font-size: ${({ theme }) => theme.fontSize.large.pc};
    }
    > * + h3 {
      margin-top: ${({ theme }) => theme.margin.content.pc};
    }
  }
`;

export default Content;
