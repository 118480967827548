import 'intersection-observer';
import { library } from '@fortawesome/fontawesome-svg-core';
import {
  faTwitter,
  faFacebookF,
  faInstagram,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons';

export const onClientEntry = () => {
  library.add(faTwitter);
  library.add(faFacebookF);
  library.add(faInstagram);
  library.add(faYoutube);
};

// NOTE:
// 何故かbuild時にlocationが無いというエラーが出る。
// 要検証

// export const shouldUpdateScroll = ({
//   routerProps: { location },
//   prevRouterProps: { location: prevLocation },
// }) => {
//   if (location.pathname === prevLocation.pathname) {
//     return false;
//   }
//   return true;
// };
