import React from 'react';
import styled from '@emotion/styled';

type Props = {
  children: string;
};

export const ScreenReaderText: React.FC<Props> = ({ children }: Props) => (
  <StyleScreenReaderText>{children}</StyleScreenReaderText>
);

const StyleScreenReaderText = styled.span`
  display: inline-block;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: polygon(0px 0px, 0px 0px, 0px 0px, 0px 0px);
  position: absolute !important;
  white-space: nowrap;
  height: 1px;
  width: 1px;
  overflow: hidden;
`;

export default ScreenReaderText;
