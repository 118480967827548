import React from 'react';

export type Props = {
  search: string;
};

export const QueryStringContext: React.Context<Props> = React.createContext<Props>(
  {
    search: '',
  }
);

export default QueryStringContext;
