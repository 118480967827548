import React from 'react';

export type Props = {
  loadedPostsCache?: Record<string, unknown>;
  setLoadedPostsCache?: React.Dispatch<
    React.SetStateAction<Record<string, unknown> | undefined>
  >;
};

export const LoadedPostsCacheContext: React.Context<Props> = React.createContext<Props>(
  {
    loadedPostsCache: {},
  }
);

export default LoadedPostsCacheContext;
