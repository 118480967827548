import * as React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import Logo from 'src/images/logo_large.inline.svg';
import type { GetSiteTitleForTitleLargeQuery } from 'types/graphql-type';

const staticQuery = graphql`
  query GetSiteTitleForTitleLarge {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const TitleLarge: React.FC = () => {
  const { site } = useStaticQuery<GetSiteTitleForTitleLargeQuery>(staticQuery);
  return (
    <StyleTitleLarge>
      <Logo aria-label={site?.siteMetadata?.title ?? undefined} />
    </StyleTitleLarge>
  );
};

const StyleTitleLarge = styled.figure`
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    position: absolute;
    top: 25px;
    z-index: 1001;
    max-width: 203px;
    min-width: 140px;
    width: ${({ theme }) =>
      `calc(100vw - ${theme.minWidthMenu.pc} - (${theme.margin.site.pc} * 2) - 340px)`};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    margin-top: -${({ theme }) => theme.headerHeight.spHomeMinusMargin};
    padding-bottom: 50px;
    text-align: center;
    margin-left: auto;
    margin-right: auto;
    height: ${({ theme }) => theme.headerHeight.spHome};
  }

  svg {
    @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
      * {
        fill: white;
      }
    }
    @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
      height: 100%;
      width: auto;
    }
  }
`;

export default TitleLarge;
