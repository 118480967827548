import React, { useCallback } from 'react';
import { Link } from 'gatsby';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { FlexibleLink } from './flexible-link';
import type { IconName } from '@fortawesome/fontawesome-svg-core';

type Props = {
  className?: string;
  openMobileMenu: boolean;
  setOpenMobileMenu: React.Dispatch<React.SetStateAction<boolean>>;
};

const MAIN_MENUS = [
  {
    to: 'introduction',
    label: 'はじめに',
  },
  {
    to: 'program-overview',
    label: 'プログラム概要',
  },
  {
    to: '/event/',
    label: '開催プログラム',
  },
  {
    to: 'area-campus',
    label: 'エリア・キャンパス',
  },
  {
    to: 'outline',
    label: '事業概要',
  },
];

const SNS_MENUS: { href: string; iconName: IconName }[] = [
  {
    href: 'https://twitter.com/chiikigeinou',
    iconName: 'twitter',
  },
  {
    href: 'https://www.facebook.com/chiikigeinou',
    iconName: 'facebook-f',
  },
  {
    href: 'https://www.instagram.com/chiikigeinou',
    iconName: 'instagram',
  },
  {
    href: 'https://www.youtube.com/channel/UCkUh63CErPhhpVqjoTSZZUg',
    iconName: 'youtube',
  },
];

export const Navigation: React.FC<Props> = ({
  className,
  openMobileMenu,
  setOpenMobileMenu,
}: Props) => {
  const handleClick = useCallback(() => {
    setOpenMobileMenu(false);
  }, [setOpenMobileMenu]);
  return (
    <StyleNavigation
      className={`${className ?? ''} ${
        openMobileMenu ? 'mobile-menu-open' : ''
      }`}
    >
      <StyledMainMenu>
        {MAIN_MENUS.map((menu, index) => (
          <StyledMainMenuListItem key={`main-menu-${index}`}>
            {menu.to.startsWith('/') && (
              <StyledMainMenuLink to={menu.to} onClick={handleClick}>
                {menu.label}
              </StyledMainMenuLink>
            )}
            {!menu.to.startsWith('/') && (
              <StyledMainMenuFlexibleLink to={menu.to} onClick={handleClick}>
                {menu.label}
              </StyledMainMenuFlexibleLink>
            )}
          </StyledMainMenuListItem>
        ))}
      </StyledMainMenu>
      <StyledSNSMenu>
        {SNS_MENUS.map((menu, index) => (
          <StyledSNSMenuListItem key={`sns-menu-${index}`}>
            <StyledSNSMenuListAnchor
              href={menu.href}
              target="_blank"
              rel="noopener noreferrer"
            >
              <FontAwesomeIcon icon={['fab', menu.iconName]} />
            </StyledSNSMenuListAnchor>
          </StyledSNSMenuListItem>
        ))}
      </StyledSNSMenu>
    </StyleNavigation>
  );
};

const StyleNavigation = styled.nav`
  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    display: none;
    &.mobile-menu-open {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      position: fixed;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      background-color: white;
    }
  }

  li {
    @media (min-width: ${(props) =>
        props.theme.breakpoints.ipadVertical + 1}px) {
      margin-bottom: 0;
    }
  }
`;

// NOTE:
// なぜかcssが動作していない。
const baseStyleMainMenuAnchor = css`
  /* cursor: pointer;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.05s ease-out;
    transform: scaleX(0);
    transform-origin: center right;
  }
  &.active,
  &:hover {
    text-decoration: none;
    &::after {
      will-change: transform;
      transform: scaleX(1);
      transform-origin: center left;
    }
  }
  &:active,
  &:focus {
    text-decoration: none;
  } */
`;

const StyledMainMenuLink = styled(Link)`
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.05s ease-out;
    transform: scaleX(0);
    transform-origin: center right;
  }
  &.active,
  &:hover {
    text-decoration: none;
    &::after {
      will-change: transform;
      transform: scaleX(1);
      transform-origin: center left;
    }
  }
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const StyledMainMenuFlexibleLink = styled(FlexibleLink)`
  cursor: pointer;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: currentColor;
    transition: transform 0.05s ease-out;
    transform: scaleX(0);
    transform-origin: center right;
  }
  &.active,
  &:hover {
    text-decoration: none;
    &::after {
      will-change: transform;
      transform: scaleX(1);
      transform-origin: center left;
    }
  }
  &:active,
  &:focus {
    text-decoration: none;
  }
`;

const StyledMainMenuListItem = styled.li`
  line-height: 1;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    & + * {
      margin-left: 1em;
    }
  }
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadHorizontal}px) {
    & + * {
      margin-left: 2em;
    }
  }
  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    margin-bottom: 50px;
  }
`;

const StyledMainMenu = styled.ul`
  list-style: none;
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    display: flex;
    justify-content: flex-end;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    text-align: center;
  }
`;

const StyledSNSMenuListAnchor = styled.a`
  &:hover {
    color: grey;
  }
`;

const StyledSNSMenuListItem = styled.li`
  line-height: 1;
  font-size: 1.6rem;
  & + * {
    margin-left: 2em;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.ipadVertical + 1}px) {
    font-size: 1.2rem;
    & + * {
      margin-left: 1.5em;
    }
  }
`;

const StyledSNSMenu = styled.ul`
  display: flex;
  list-style: none;
  margin: 0;
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-top: 20px;
    justify-content: flex-end;
  }
`;

export default Navigation;
