import React from 'react';

export interface Props {
  pathname: string;
}

export const PathnameContext: React.Context<Props> = React.createContext<Props>(
  {
    pathname: '',
  }
);

export default PathnameContext;
