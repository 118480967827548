import '@emotion/react';

const base = 15;
export const theme = {
  colors: {
    // primary: '#3D63AE',
    bg: '#fff',
    black: '#000',
  },
  maxWidth: '1000px',
  maxWidthText: '670px',
  headerHeight: {
    spHome: '35vh',
    spHomeMinusMargin: '47px',
    spBar: '70px',
  },
  minWidthMenu: {
    pc: '530px',
  },
  fontFamily: {
    // NOTE:
    // mainはtypography.js側で設定
    garamond: `'EB Garamond', serif`,
  },
  fontSize: {
    xxxxlarge: {
      sp: `${18 / base}rem`,
      pc: `${35 / base}rem`,
    },
    xxxlarge: {
      sp: `${18 / base}rem`,
      pc: `${30 / base}rem`,
    },
    xxlarge: {
      sp: `${18 / base}rem`,
      pc: `${25 / base}rem`,
    },
    xlarge: {
      sp: `${15 / base}rem`,
      pc: `${20 / base}rem`,
    },
    large: {
      sp: `${13 / base}rem`,
      pc: `${18 / base}rem`,
    },
    normal: {
      sp: '13px',
      pc: '15px',
    },
  },
  margin: {
    site: {
      sp: '20px',
      pc: '50px',
    },
    main: {
      sp: '0px',
      pc: '8%',
    },
    content: {
      sp: '30px',
      pc: '90px',
    },
  },
  breakpoints: {
    iphoneVertical: 375,
    ipadVertical: 768,
    ipadHorizontal: 1024,
    ipadProHorizontal: 1300,
  },
};

type ThemeType = typeof theme;

declare module '@emotion/react' {
  export interface Theme extends ThemeType {}
}

export default theme;
