import React, { useContext } from 'react';
import { Link } from 'gatsby';
import { Link as ReactScrollLink } from 'react-scroll';
import { PathnameContext } from 'src/contexts/pathname-context';
import type { Props as PathnameContextProps } from 'src/contexts/pathname-context';

type FlexibleLinkProps = {
  className?: string;
  to: string;
  children: string;
  onClick?: () => void;
};

export const FlexibleLink: React.FC<FlexibleLinkProps> = ({
  className,
  to,
  children,
  onClick,
}: FlexibleLinkProps) => {
  const { pathname } = useContext<PathnameContextProps>(PathnameContext);
  if (pathname === '/') {
    return (
      <ReactScrollLink
        className={className}
        to={to}
        spy={true}
        smooth={true}
        duration={300}
        onClick={onClick}
        offset={0}
      >
        {children}
      </ReactScrollLink>
    );
  }
  return (
    <Link className={className} to={`/#${to}`} onClick={onClick}>
      {children}
    </Link>
  );
};

export default FlexibleLink;
