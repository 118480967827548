import React from 'react';

export interface Props {
  isMobile: boolean;
}

export const MobileWidthContext: React.Context<Props> = React.createContext<Props>(
  {
    isMobile: false,
  }
);

export default MobileWidthContext;
