import * as React from 'react';

type Props = {
  children?: React.ReactNode;
  props?: any;
};

export const SkipNavLink: React.FC<Props> = ({ children, ...props }) => (
  <a {...props} href={`#reach-skip-nav`} data-reach-skip-link>
    {children && children}
  </a>
);

export default SkipNavLink;
