import * as React from 'react';
import styled from '@emotion/styled';
import { Title } from './title';
import { Navigation } from './navigation';
import { MenuToggleButton } from './menu-toggle-button';
import { MobileWidthContext } from 'src/contexts/mobile-width-context';
import { PathnameContext } from 'src/contexts/pathname-context';
import type { Props as MobileWidthContextProps } from 'src/contexts/mobile-width-context';
import type { Props as PathnameContextProps } from 'src/contexts/pathname-context';

const useIsMounted = () => {
  const [isMounted, setIsMounted] = React.useState(false);
  React.useLayoutEffect(() => {
    setIsMounted(true);
  }, []);
  return isMounted;
};

const SHOW_SCROLL_VALUE = 500;
const useShowHomeTitle = (isHome: boolean) => {
  const [showHomeTitle, setShowHomeTitle] = React.useState<boolean>(false);
  React.useLayoutEffect(() => {
    const scrollTarget: Element =
      window.document.scrollingElement || window.document.body;
    function handleScroll() {
      if (scrollTarget.scrollTop > SHOW_SCROLL_VALUE) {
        setShowHomeTitle(true);
      } else {
        setShowHomeTitle(false);
      }
    }
    if (isHome) {
      setShowHomeTitle(false);
      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    } else {
      setShowHomeTitle(true);
    }
  }, [isHome]);
  return showHomeTitle;
};

export const Header: React.FC = () => {
  const isMounted = useIsMounted();
  const { pathname } = React.useContext<PathnameContextProps>(PathnameContext);
  const isHome = pathname === '/' && isMounted;
  const showHomeTitle = useShowHomeTitle(isHome);
  const { isMobile } = React.useContext<MobileWidthContextProps>(
    MobileWidthContext
  );
  const [openMobileMenu, setOpenMobileMenu] = React.useState<boolean>(false);
  return (
    <StyledHeader
      className={`${isHome ? 'home' : ''} ${
        showHomeTitle ? 'show-home-title' : ''
      }`}
    >
      <StyledTitle showHomeTitle={showHomeTitle} />
      <StyledNavigation
        openMobileMenu={isMobile ? openMobileMenu : false}
        setOpenMobileMenu={setOpenMobileMenu}
      />
      {isMobile && (
        <MenuToggleButton
          openMobileMenu={openMobileMenu}
          setOpenMobileMenu={setOpenMobileMenu}
        />
      )}
    </StyledHeader>
  );
};

const StyledTitle = styled(Title)`
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    flex: 1 1 200px;
    max-width: 80%;
  }
`;

const StyledNavigation = styled(Navigation)`
  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    margin-left: auto;
    min-width: ${({ theme }) => theme.minWidthMenu.pc};
  }
`;

const StyledHeader: React.FC<any> = styled.header`
  height: ${({ theme }) => theme.headerHeight.spBar};
  display: flex;
  justify-content: space-between;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100vw;
  margin-left: calc(50% - 50vw);
  margin-right: calc(50% - 50vw);
  padding-top: ${({ theme }) => theme.margin.site.sp};
  padding-left: ${({ theme }) => theme.margin.site.sp};
  padding-right: ${({ theme }) => theme.margin.site.sp};

  @media (min-width: ${({ theme }) => theme.breakpoints.ipadVertical + 1}px) {
    height: 100px;
    padding-top: 25px;
    padding-left: ${({ theme }) => theme.margin.site.pc};
    padding-right: ${({ theme }) => theme.margin.site.pc};
    &.home {
      position: fixed;
      ${StyledNavigation} {
        color: white;
      }
      &.show-home-title {
        ${StyledNavigation} {
          color: black;
        }
      }
    }
    &:not(.home) {
      position: sticky;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
    position: sticky;
    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 0;
      background-color: white;
      transition: 0.1s cubic-bezier(0.19, 1, 0.22, 1);
    }

    &.home {
      &.show-home-title {
        &::before {
          transition-duration: 0.15s;
          height: 100%;
        }
      }
    }

    &:not(.home) {
      @media (max-width: ${({ theme }) => theme.breakpoints.ipadVertical}px) {
        background-color: white;
      }
    }
  }
`;

export default Header;
