import React, { useState, useLayoutEffect, useContext } from 'react';
import styled from '@emotion/styled';
import PathnameContext from 'src/contexts/pathname-context';
import type { Props as PathnameContextProps } from 'src/contexts/pathname-context';

export const Transition: React.FC = ({ children }) => {
  const [show, setShow] = useState<boolean>(false);
  const { pathname }: PathnameContextProps = useContext<PathnameContextProps>(
    PathnameContext
  );
  useLayoutEffect(() => {
    setTimeout(() => {
      setShow(true);
    }, 0);
    return () => {
      setShow(false);
    };
  }, [pathname]);
  return (
    <StyleTransition className={show ? 'show' : ''}>{children}</StyleTransition>
  );
};

const StyleTransition = styled.div`
  opacity: 0;
  transform: translateY(-5px);
  &.show {
    transition: opacity 0.2s ease-out, transform 0.2s ease-out;
    opacity: 1;
    transform: none;
  }
`;

export default Transition;
