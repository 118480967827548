import * as React from 'react';
import { graphql, Link, useStaticQuery } from 'gatsby';
import styled from '@emotion/styled';
import { PathnameContext } from 'src/contexts/pathname-context';
import Logo from 'src/images/logo_small.inline.svg';
import type { Props as PathnameContextProps } from 'src/contexts/pathname-context';
import type { GetSiteTitleQuery } from 'types/graphql-type';

type Props = {
  className?: string;
  showHomeTitle: boolean;
};

const staticQuery = graphql`
  query GetSiteTitle {
    site {
      siteMetadata {
        title
      }
    }
  }
`;

export const Title: React.FC<Props> = ({ className, showHomeTitle }: Props) => {
  const { site } = useStaticQuery<GetSiteTitleQuery>(staticQuery);
  const { pathname } = React.useContext<PathnameContextProps>(PathnameContext);
  const isHome = pathname === '/';
  return (
    <StyleTitle
      className={`${className ?? ''} ${isHome ? 'home' : 'not-home'} ${
        showHomeTitle ? 'show' : ''
      }`}
      as={isHome ? 'h1' : 'p'}
    >
      <Link to="/">
        <Logo aria-label={site?.siteMetadata?.title ?? undefined} />
      </Link>
    </StyleTitle>
  );
};

const StyleTitle = styled.h1<{ as: string }>`
  margin: 0;
  padding-right: 10px;

  &.home {
    opacity: 0;
    transform: translateY(-8px);
    transition: 0.1s cubic-bezier(0.19, 1, 0.22, 1);
    pointer-events: none;
    &.show {
      transition-duration: 0.3s;
      opacity: 1;
      transform: translateY(0);
      pointer-events: auto;
    }
  }

  a,
  svg {
    display: block;
  }
  svg {
    max-width: 270px;
  }
`;

export default Title;
